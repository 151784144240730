$primary: #cc0202;
$secondary: white;

@import '~bootstrap/scss/bootstrap';

hr {
  border-top: 2px solid #ccc;
  margin: 20px 0;
}

.no-focus-shadow:focus {
  box-shadow: none !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
}

